<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" class="mt-n3">
          <paginated-table
            create-new-item-label="Create New Survey"
            :headers="headers"
            :items="items"
            :pagination-details="paginationDetails"
            :showSearchBox="true"
            :searchPlaceholder="'Search survey title...'"
            @updateSearchValue="searchByValue"
            @createNewItem="createNewItem"
            @editItem="editItem"
            @fetchNextPage="fetchDataByUrl(paginationDetails.nextPageUrl)"
            @fetchPreviousPage="
              fetchDataByUrl(this.paginationDetails.previousPageUrl)
            "
            @updateItemsPerPage="updateItemsPerPage"
            @updateItemStatus="updateItemStatus"
            @updateSelectedPage="updateSelectedPage"
          ></paginated-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import PaginatedTable from "@/components/Tables/PaginatedTable.vue";

export default {
  name: "surveys",
  components: {
    HeaderTopDashboard,
    PaginatedTable,
  },
  data() {
    return {
      headers: [
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },
        { text: "Start Date", value: "start_date" },
        { text: "End Date", value: "end_date" },
        { text: "# Questions", value: "numQuestions" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      fullItems: [],
      paginationDetails: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        itemsLength: 0,
        firstPageUrl: "",
        lastPageUrl: "",
        nextPageUrl: "",
        previousPageUrl: "",
        links: [],
        path: "",
      },
      searchValue: "",
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    createNewItem() {
      this.$router.push("/surveys/create");
    },

    editItem(item) {
      this.$router.push({
        name: "Edit Survey",
        params: {
          id: item.id.toString(),
        },
      });
    },

    fetchDataByUrl(url) {
      if (!url) {
        // TODO - print alert.
        return;
      }

      if (!url.includes("search?")) {
        this.paginationDetails.loading = true;
        this.$http
          .get(url, this.sessionStore.getHttpConfig)
          .then((result) => {
            this.items.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const survey = result.data.data[i];
              this.fullItems.push(survey);
              this.items.push({
                id: survey.id,
                title: survey.title,
                description: survey.description,
                audience: survey.audience,
                start_date: survey.start_date,
                end_date: survey.end_date,
                status: survey.status,
                created_at: survey.created_at,
                updated_at: survey.updated_at,
                numQuestions: survey.survey_questions.length,
              });
            }

            this.setPaginationDetails(result.data);
          })
          .catch((error) => {
            console.error("Error: ", error);
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      } else {
        this.paginationDetails.loading = true;

        const body = [
          {
            field: "name",
            operator: "like",
            value: this.searchValue,
          },
        ];

        this.$http
          .post(url, body, this.sessionStore.getHttpConfig)
          .then((result) => {
            this.items.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const survey = result.data.data[i];
              this.fullItems.push(survey);
              this.items.push({
                id: survey.id,
                title: survey.title,
                description: survey.description,
                audience: survey.audience,
                start_date: survey.start_date,
                end_date: survey.end_date,
                status: survey.status,
                created_at: survey.created_at,
                updated_at: survey.updated_at,
                numQuestions: survey.survey_questions.length,
              });
            }

            this.setPaginationDetails(result.data);
          })
          .catch((error) => {
            console.error("Error: ", error);
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      }
    },

    searchByValue(val) {
      this.searchValue = val;

      if (!val) {
        this.fetchDataByUrl("/surveys");
        return;
      }

      const body = [
        {
          field: "name",
          operator: "like",
          value: val,
        },
      ];

      this.$http
        .post("/surveys/search", body, this.sessionStore.getHttpConfig)
        .then((result) => {
          this.items.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            const survey = result.data.data[i];
            this.fullItems.push(survey);
            this.items.push({
              id: survey.id,
              title: survey.title,
              description: survey.description,
              audience: survey.audience,
              start_date: survey.start_date,
              end_date: survey.end_date,
              status: survey.status,
              created_at: survey.created_at,
              updated_at: survey.updated_at,
              numQuestions: survey.survey_questions.length,
            });
          }

          this.setPaginationDetails(result.data);
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.success("An error occurred while searching the shop.");
        });
    },

    updateItemsPerPage(newValue) {
      const searchParams = new URLSearchParams({
        size: newValue,
      });

      const url = `${this.paginationDetails.path}?${searchParams.toString()}`;

      this.fetchDataByUrl(url);
    },

    updateItemStatus(item, status) {
      const body = {
        status,
      };

      this.$http
        .patch(`/surveys/${item.id}`, body, this.sessionStore.getHttpConfig)
        .then(() => {
          this.fetchDataByUrl("/surveys");
          this.$notify.success("Status updated successfully.");
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.success("An error occurred while updating the status.");
        });
    },
  },
  mounted() {
    this.fetchDataByUrl("/surveys");
  },
};
</script>
